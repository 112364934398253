
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'passportCombineExplain',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class PassportCombineExplainPage extends Vue {
  @Provide() public tips: any = []

  public created () {
    this.refresh()
  }

  @Emit()
  public async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'Account/MergeTips'
      })
      this.tips = res.value
    } catch (err) {
    }
  }

  @Emit()
  public relatedNext () {
    const url = encodeURI(encodeURI(`/b/combine`))
    this.$router.replace(url)
  }

  @Emit()
  public backBind () {
    const url = encodeURI(encodeURI(`/b/phone`))
    this.$router.replace(url)
  }

  @Emit()
  private goBack () {
    window.history.back()
  }
}
